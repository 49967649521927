import React from 'react';
import { motion } from 'framer-motion';

const cards = [
    {
      url: require("../assets/logos/html.png"),
      caption: 'HTML',
    },
    {
      url: require("../assets/logos/css.png"),
      caption: 'CSS',
    },
    {
      url: require("../assets/logos/javascript.png"),
      caption: 'JavaScript',
    },
    {
      url: require("../assets/logos/Typescript.png"),
      caption: 'TypeScript',
    },
    {
      url: require("../assets/logos/react.png"),
      caption: 'React',
    },
    {
      url: require("../assets/logos/redux.png"),
      caption: 'Redux',
    },
    {
      url: require("../assets/logos/materialUI.png"),
      caption: 'Material UI',
    },
    {
      url: require("../assets/logos/bootstrap.png"),
      caption: 'Bootstrap',
    },
    {
      url: require("../assets/logos/git.png"),
      caption: 'Git',
    },
    {
      url: require("../assets/logos/github.png"),
      caption: 'Github',
    },
    {
      url: require("../assets/logos/firebase.png"),
      caption: 'Firebase',
    },
    {
      url: require("../assets/logos/figma.png"),
      caption: 'Figma',
    },
    {
      url: require("../assets/logos/sass.jpg"),
      caption: 'SASS',
    },
  ];

  const animationOpacity = {
    hidden: {
      y: 30,
      opacity: 0,
    },
    visible: custom=>({
      y: 0,
      opacity: 1,
      transition: {
        delay: custom * 0.2
      }
    })
  };
const TechSkills = () => {
  return (
    <div className='tech-skills-container'>
        <div className='tech-skills-content-wrapper'>
            <h2>tech stack and skills</h2>
            <p>Throughout my career, I have successfully delivered high-quality projects. I possess a solid understanding of front-end development principles and best practices, and I constantly strive to stay up-to-date with the latest trends and technologies in the field.</p>
        </div>
        <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        className='tech-logo-wrapper'>
            {cards.map((card, index)=>(
            <motion.div custom={1+index} variants={animationOpacity} className='tech-logo-card' key={index}>
                <img src={card.url} alt={card.caption} />
                <p>{card.caption}</p>
            </motion.div>
            ))}
        </motion.div>
  
    </div>
  );
};

export default TechSkills;