import React from 'react';
import Header from '../header/Header';
import { motion } from 'framer-motion';

const animation = {
  hidden: {
    y: 200,
    opacity: 0,
  },
  visible: custom => ({
    y: 0,
    opacity: 1,
    transition: {
      delay : custom * 0.2,
      duration: 0.8
    }
  })
};

const LandingPage = () => {
  return (
    <div className='landing-page'>
        <Header/>
        <motion.div
        className="landing-page-container"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount:0.8 }}
        >
            <motion.h2 custom={1} variants={animation} className="home-h2">Hello, I'm</motion.h2>
            <motion.h1 custom={2} variants={animation} className="home-h1">Zada Kydyrbayeva</motion.h1>
            <motion.h3 custom={3} variants={animation}>Creative front end developer!</motion.h3>
            <motion.div custom={4} variants={animation}>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/document/d/e/2PACX-1vShcpZw8uMFCDjqOFGUv3-pB8m0y6cDhcjyEo8B5pGOTXO1IqitgiZO13d44blcGNZEJ_wgSuETIsxF/pub"
                >
                    My Resume
                </a>
                <a href="#contact">Contact Me</a>
            
            </motion.div>
            <img src={require("../assets/images/zadaPic.png")} alt='ing'/>
        </motion.div>
    </div>
  );
};

export default LandingPage;