import React from 'react';
import { motion } from 'framer-motion';
const skills = [
  {
    title: "Dedication",
    desc: "I am fully committed to the success of every project, going the extra mile to deliver exceptional results.",
    img: require("../assets/icons/dedication.png")
  },
  {
    title: "Problem-Solving Skills",
    desc : "I am adept at problem-solving, approaching challenges with a logical and analytical mindset to overcome obstacles.",
    img: require("../assets/icons/problem-solving.png")
  },
  {
    title:"Fresh Perspective",
    desc: "With a fresh perspective, I bring innovative ideas and creative solutions to every project, ensuring a unique and impactful outcome.",
    img: require("../assets/icons/light-bulb.png")
  },
  { 
    title: "Attention to Detail",
    desc: "I pay meticulous attention to detail, ensuring precision and accuracy in my work.",
    img: require("../assets/icons/details-attention.png")
  }
];
const animation = {
    hidden: {
      x: -300,
      opacity:0.5,
      transition: {
        delay: 2,
        duration: 1.2
      }
    },
    visible: custom => ({
      x: 0,
      opacity: 1,
      transition: {
        delay : custom * 0.2,
        duration: 1.2
      }
    })
  };
const WhyMe = () => {
  return (
    <motion.div
    className='why-me-container'
    initial="hidden"
    whileInView="visible"
    viewport={{ once: false, amount:0.8 }}
    >
        <motion.h1 custom={1} variants={animation}>why me?</motion.h1>
        <ul>
          {skills.map((el, index)=>{
          return <li key={index} className={ index%2 === 0 ? "li-even" : "li-odd"}>
            <img src={el.img} alt={el.title} />
            <h4>{el.title}</h4>
            <p>{el.desc}</p>
          </li>;
          })}
        </ul>
    </motion.div>
  );
};

export default WhyMe;