import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@mui/material';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const ref = useRef();
  const [success, setSuccess] = useState("");
  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('invalid email address')
        .required('*please type your email'),
    }),
    onSubmit: (values) => {
      emailjs.sendForm('service_sbt3kwa', 'template_fdoiw9r', ref.current , 'i3S_AcAWZ4F1Ng2m5')
      .then((result) => {
        setSuccess("Thank you, I will reach out soon");
      }, (error) => {
        setSuccess("Oh, No! something went wrong, please try again later");
      });
      formik.resetForm();
    },
  });
  return (
    <form ref={ref} onSubmit={formik.handleSubmit} className="form-container">
      <h1>get in touch</h1>
      <input
        id="name"
        className="contact-formik-inputs"
        name="name"
        placeholder="Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
      />
      <input
        id="lastName"
        className="contact-formik-inputs"
        name="lastName"
        placeholder="Last Name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.lastName}
      />
      <input
        id="phone"
        className="contact-formik-inputs"
        name="phone"
        placeholder="Phone Number"
        type="number"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.phone}
      />
      <input
        id="email"
        className="contact-formik-inputs"
        name="email"
        placeholder="Email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
      />
      
      <input
        id="message"
        className="contact-formik-inputs"
        name="message"
        placeholder="Message"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.message}
        />
      {formik.touched.email && formik.errors.email && (
        <div className='error-message'>{formik.errors.email}</div>
      )}
      {success && <p>{success}</p>}
      <Button id="contact-formik-btn" type='submit'>Send</Button>
    </form>
  );
};

export default Contact;
