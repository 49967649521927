import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
const styleLink= {
  display:"block",
  textDecoration:"none",
  color:"black",
  padding:"6px 10px",
  marginRight: "1rem"
};

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div >
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
          <path color="white" fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        >
        <a href="#home" onClick={handleClose} style={styleLink}>Home</a>
        
        <a href="#aboutme" onClick={handleClose} style={styleLink}>About Me</a>
        <a href="#skills" onClick={handleClose} style={styleLink}>Skills</a>
        <a href="#projects" onClick={handleClose} style={styleLink}>Projects</a>
        <a href="#contact" onClick={handleClose} style={styleLink}>Contact</a>
      </Menu>
    </div>
  );
}