import Formik from './Formik';
import React from 'react';
import { motion } from 'framer-motion';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

const Contact = () => {
  return (
    <motion.div className="contact-container"
    initial="hidden"
    whileInView="visible"
    viewport={{ once: false, amount:0.8 }}>
      <div className='contact-formik-wrapper'>
        <Formik />
      </div>
      <div className='contact-content-wrapper'>
        <h1>get in touch</h1>
        <p>I'm excited to start a conversation with you and explore the possibilities of working together. Thank you for taking the time to visit my portfolio, and I'm looking forward to hearing from you!</p>
        <a target="_blank" rel="noreferrer" href="https://github.com/Zada1997" ><GitHubIcon/></a>
        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/zada-kydyrbayeva" ><LinkedInIcon/></a>
      </div>
      
    </motion.div>
  );
};

export default Contact;
