import AboutMe from './components/main/AboutMe';
import Contact from './components/main/Contact';
import LandingPage from './components/main/LandingPage';
import TechSkills from './components/main/TechSkills';
import WhyMe from './components/main/WhyMe';
import './styles/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className="App">
      <section id="home"> <LandingPage/> </section>
      <section id="aboutme"><AboutMe/></section>
      <section id="skills"><TechSkills/></section>
      <section id="why-me"><WhyMe/></section>
      <section id="contact"><Contact/></section>
    </div>
  );
};
export default App;
