import React from 'react';
import Layout from './Layout';
import SideBar from './SideBar';

const Header = () => {

  return (
    <div className="header-layout">
        <Layout/>
        <SideBar placement="end"/>
    </div>
  );
};

export default Header;