import React from 'react';
import { motion } from 'framer-motion';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
const animation = {
  hidden: {
    x: -200,
    opacity: 0
  },
  visible: custom => ({
    x: 0,
    opacity:1,
    transition: {
      delay: custom * 0.2,
      duration: 0.8
    }
  })
};
const animationOpacity = {
  hidden: {
    opacity: 0
  },
  visible: custom=>({
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration:0.8
    }
  })
};
const AboutMe = () => {
  return (
    <motion.div
    className="about-me"
    initial="hidden"
    whileInView="visible"
    viewport = {{once: false, amount: 0.3}}
    >
      <motion.div className="image-wrapper">
        <img src={require("../assets/images/cartoonPic.png")} alt="pic-Zada"/>
      </motion.div>
      <motion.div custom={2} variants={animation} className="summary-wrapper">
        <motion.h2 className="summary-h2" custom={3} variants={animationOpacity}>about me</motion.h2>
        <motion.div custom={4} variants={animationOpacity}>
          <p> I am a barista turned Software Engineer from Orlando, Florida.</p>
          <p className='summary-desc'>
          I've traded in my coffee beans for lines of code to create magical digital experiences. With a passion for technology and a drive to learn, I bring a fresh perspective and a strong work ethic to every project. And I constantly strive to stay up-to-date with the latest trends and technologies in the field.
          <br/>
          <br/>
          When I'm not coding, you'll find me concocting delightful coffee creations.
          <br/>
          <br/>
          <span>Let's connect and create something extraordinary together!</span>
          <br/>
          <br/>
          <a target="_blank" rel="noreferrer" href="https://github.com/Zada1997" ><GitHubIcon/></a>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/zada-kydyrbayeva" ><LinkedInIcon/></a>
          </p>
        </motion.div>
      </motion.div>
      {/* <motion.div className="image-wrapper">
        <img src={require("../assets/images/cartoonPic.png")} alt="pic-Zada"/>
      </motion.div> */}
    </motion.div>
  );
};

export default AboutMe;
