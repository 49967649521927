import { Link } from '@mui/material';
import React from 'react';

const aStyle = {
  textDecoration:"none",
};
const Layout = ({ handleClose }) => {
  return (
    <>
        <Link onClick={handleClose} style={aStyle} href="#home" data-rr-ui-event-key="#home">Home</Link>
        <Link onClick={handleClose} style={aStyle} href="#aboutme" data-rr-ui-event-key="#aboutme">About</Link>
        <Link onClick={handleClose} style={aStyle} href="#skills" data-rr-ui-event-key="#skills">Skills</Link>
        <Link onClick={handleClose} style={aStyle} href="#projects" data-rr-ui-event-key="#projects">Projects</Link>
        <Link onClick={handleClose} style={aStyle} href="#contact" data-rr-ui-event-key="#contact">Contact</Link>
    </>
  );
};

export default Layout;
